<template>
  <div>
    <Header nav="5"></Header>

    <div class="loading" v-if="showloading">
      <van-loading type="spinner" color="#206C47" size="24px" vertical>
        加载中...
      </van-loading>
    </div>

    <div class="container" v-else>
      <div class="banner">
        <img :src="bannerImg" />
      </div>

      <div class="pagecontent">
        <div class="en"><span class="En">C</span><span>ONTACT US</span></div>
        <div class="ch">联系我们</div>
        <div class="contactMsg">
          <div class="contact">
            <div class="contactIcon">
              <img class="icon1" src="../assets/icon011.png" />
            </div>
            <div class="contactTxt">：{{ footerList.web_mobile }}</div>
          </div>
          <a class="contact" href="mailto:service@pyua.net">
            <div class="contactIcon">
              <img class="icon2" src="../assets/icon022.png" />
            </div>
            <div class="contactTxt">：{{ footerList.web_email }}</div>
          </a>
          <div class="contact">
            <div class="contactIcon">
              <img class="icon3" src="../assets/icon033.png" />
            </div>
            <div class="contactTxt">：{{ footerList.web_address }}</div>
          </div>
        </div>
        <div class="pageImg">
          <div class="pageImgItem">
            <div><img :src="imgs[0]" @click="getImg(0)" /></div>
            <div class="pageImgText">
              <div>点击长按</div>
              <div>关注官方公众号</div>
            </div>
          </div>
          <div class="pageImgItem">
            <div><img :src="imgs[1]" @click="getImg(1)" /></div>
            <div class="pageImgText">
              <div>点击长按</div>
              <div>去官方小程序购物</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import { Loading, ImagePreview } from "vant";
export default {
  components: {
    Header,
    Footer,
    [Loading.name]: Loading,
    [ImagePreview.Component.name]: ImagePreview.Component,
  },
  data() {
    return {
      bannerImg: "",
      showloading: true,
      footerList: {},
      imgs: [],
    };
  },
  mounted() {
    // 轮播图
    this.$axios
      .post("https://api1.pyua.net/index/banner", { type: 14 })
      .then((res) => {
        // console.log(res.data);
        if (res && res.code == 1) {
          this.bannerImg = res.data.arr[0].image;
          setTimeout(() => {
            this.showloading = false;
          }, 300);
        }
      });

    this.$axios.post("https://api1.pyua.net/index/web").then((response) => {
      // console.log(response.data);
      if (response && response.code == 1) {
        this.footerList = response.data;
      }
    });

    this.$axios
      .post("https://api1.pyua.net/index/content", { type: 14 })
      .then((response) => {
        // console.log(response.data.arr[0]);
        if (response && response.code == 1) {
          let arr = response.data.arr[0];
          this.imgs = [arr.image, arr.image_logo];
        }
      });
  },
  methods: {
    // 点击图片放大
    getImg(index) {
      ImagePreview({
        images: this.imgs, // 预览图片的那个数组
        showIndex: true,
        loop: false,
        startPosition: index, // 指明预览第几张图
      });
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  box-sizing: border-box;
  background-color: #f5f5f5;
}
.banner {
  margin-top: 2.75rem;
  width: 100%;
  height: 100%;
  font-size: 0;
  img {
    width: 100%;
    height: 100%;
  }
}

.loading {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  margin-top: 60%;
}

.en {
  font-family: "DIN";
  margin-top: 2.9375rem;
  margin-bottom: 0.3125rem;
  color: #d5e1db;
  font-size: 18px;
  line-height: 1;
  .En {
    font-size: 28px;
    line-height: 1;
  }
}
.ch {
  line-height: 1;
  color: #206c47;
  font-weight: 700;
  font-size: 18px;
}

.pagecontent {
  margin: 0 1rem;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
  .contactMsg {
    line-height: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 3.125rem;
    .contact {
      display: flex;
      align-items: center;
      margin-top: 1.625rem;
      &:nth-child(1) {
        margin: 0;
      }
      .contactIcon {
        width: 1.25rem;
        height: 1.25rem;
        display: flex;
        align-items: center;
        justify-content: center;
        .icon1 {
          width: 1.0625rem;
          height: 1.0625rem;
        }
        .icon2 {
          width: 1.125rem;
          height: 0.875rem;
        }
        .icon3 {
          width: 0.75rem;
          height: 1rem;
        }
      }
      .contactTxt {
        font-size: 1rem;
        color: #333;
      }
    }
  }
  .pageImg {
    width: 100%;
    padding: 1.875rem 1.875rem 3.0625rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .pageImgItem {
      flex: 1;
      font-size: 0.75rem;
      color: #333;
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        width: 5.625rem;
        height: 5.625rem;
      }
      .pageImgText {
        display: flex;
        flex-direction: column;
        align-items: center;
        line-height: 1.5;
        margin-top: 1rem;
      }
    }
  }
}
</style>